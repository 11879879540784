<template>
  <div class="content" v-cloak>
    <!-- 标题 -->
    <h5>{{msg.title}}</h5>
    <!-- 时间 -->
    <div class="time">发布时间：{{timestampToTime(msg.createtime)}}</div>
    <!-- 图片 -->
    <!-- <div class="photo"><img src="" alt=""></div> -->
    <!-- 文章 -->
    <div class="text" v-html='msg.content'  style="margin-top:  0.67rem">　
   
　</div>
<!-- 作者 -->
<!-- <div class="box"><div class="author">作者：明天是个好天气</div></div> -->


  </div>
</template>
<script>
import colleges from "../../plugin/colleges";
import qs from "qs";
export default {
    data() {
    return {
      currentInfoId: "",
      msg:''
    };
  },
    mounted(){
    // let currentQuery = this.$route.query;
    // this.currentInfoId = currentQuery.id;
    this.$nextTick(() => {
      this.details();
       this.timestampToTime();
    });
  },
  methods:{
    
timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
        // 详情
 details() {
      this.msg=JSON.parse(sessionStorage.getItem('value'))
    },
  }
};
</script>
<style lang="less" scoped>
.content  {
  width: 100%;

  box-sizing: border-box;
  background-color: #ebeff2;
  overflow: hidden;
  height: 100%;

} 

h5  {
  margin:0.4rem 0 0 0;
  font-size: 0.43rem;
  line-height: 0.69rem;
    padding:0  0.79rem 0  0.69rem;
}


.time  {
  font-size: 0.32rem;
  color: #939393;
  margin:0.13rem;
  float: right;
    padding:0  0.79rem 0  0.69rem;
}


.text  {

  margin-top:0.13rem;
  

  img  {
    width: 100%;
  }
}

// .box  {
//   width: 100%;
//   height:0.8rem;
//   line-height:0.8rem;
// }

// .author  {
//   font-size:0.37rem ;
//   color: #999999;
//   float: right;
// }

// .source  {
//   font-size: 0.32rem;
//   color: #2e7dff;
//   float: right;
// }

</style>